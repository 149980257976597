import React, { useEffect, useState } from 'react';
import Noticias from "./Noticias";
import Videos from "./Videos";
import LayoutBanner from "./LayoutBanner";
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import Novidades from './Novidades';
import { Link } from 'react-router-dom';
import Meio from './Meio';
import BannerRH from './BannerRH';
import GoTopo from './GoTopo';
import { format } from 'date-fns';
import './css/inicio.css';
import './css/style.css';


const mesesNumeros = {
  janeiro: 1,
  fevereiro: 2,
  marco: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

export default function Inicio() {
  const [vereadores, setVereadores] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          data: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
        }));

        const noticiasOrdenadas = noticiasArray.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(noticiasOrdenadas);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = ref(databaseInstance, `${KEY_REF}/leismunicipais`);

        onValue(noticiasRef, (snapshot) => {
          const data = snapshot.val();


          if (data) {
            const newsArray = Object.keys(data).map((key) => ({
              id: key,
              title: data[key].nome,
              mes: data[key].mes,
              ano: data[key].ano,
              href: data[key].href
            }));
            setNewsData(newsArray.reverse());
          }
        });
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);

      }
    };


    fetchData();
  }, []);



  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const vereadoresRef = ref(databaseInstance, `${KEY_REF}/vereadores`);



    onValue(vereadoresRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const vereadoresArray = Object.keys(data).map((key) => ({
          id: key,
          nome: data[key].nome,
          partido: data[key].partido,
          imagem: data[key].documentoUrl
        }));

        setVereadores(vereadoresArray);
      }
    });
  }, []);

  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();
    const dataFormatada = format(new Date(noticia.data), 'dd/MM/yyyy');

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="col-md-6" key={noticia.id}>
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-primary-emphasis">NOTICIAS</strong>
            <h5 class="mb-0">{noticia.titulo}</h5>
            <div className="mb-1 text-body-secondary">{dataFormatada}</div>

            <p class="card-text mb-auto">{descricaoSemPTags}</p>
            <a href={`/noticias/${tituloParaURL}/${noticia.id}`} target="_blank" rel="noopener noreferrer" class="icon-link gap-1 icon-link-hover stretched-link">
              Continue Lendo
              <svg class="bi"></svg>
            </a>
          </div>
          <div className="col-auto d-none d-lg-block">
            <img
              src={noticia.imagem}
              alt={noticia.titulo}
              className="bd-placeholder-img zoomed-image"
              width="200"
              height="250"
              role="img"
              aria-label="Placeholder: Thumbnail"
              focusable="false"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderVereadores = (vereador) => {
    return (
      <div key={vereador.id} className="col-md-6 col-lg-3 mb-4 col-mobile" style={{ maxWidth: 230 }}>
        <a href={`/vereadores/${vereador.id}`}>
          <div className="card h-100 hover-effect" >
            <img src={vereador.imagem} className="card-img-top" alt={vereador.nome} />
            <div className="card-body">
              <h5 className="card-title" style={{ fontSize: 20, fontWeight: 'bold' }}>{vereador.nome}</h5>
              <p className="card-text" style={{ fontSize: 14 }}>{vereador.partido}</p>
            </div>
          </div>
        </a>
      </div>
    );
  };

  const renderLeis = (leis) => {
    return (
      <div class="cardHome">
        <div class="data">LEI MUNICIPAL - <time style={{ color: "#63ea09" }}>{leis.mes}, {leis.ano}</time></div>
        <h2 style={{ color: 'white' }}>{leis.title}</h2>
        <div style={{ marginTop: "-50px" }}>

          <img style={{ maxWidth: 100 }} src='../imagens/icons/portarias.png'></img>
        </div>
        <div class="autor">
          <div class="img-container">
            <div class="img"></div>
          </div>

          <div class="info">
            <span style={{ color: 'whitesmoke' }}>CÂMARA MUNICIPAL</span>
            GURJÃO - PB
          </div>
        </div>
        <div class="tags" >
          <a style={{ color: 'white' }} href={leis.href}>VISUALIZAR</a>

        </div>
      </div>
    );
  };


  const serviceData = [


    {
      icon: './imagens/icons/publicacao.png',
      name: 'Decretos',
      href: '/legislacao/decretos',
    },
    {
      icon: './imagens/icons/leis.png',
      name: 'Leis Municipais',
      href: '/legislacao/leismunicipais',
    },
    {
      icon: './imagens/icons/diario.png',
      name: 'Requerimentos',
      href: '/',
    },
    {
      icon: './imagens/icons/documentos.png',
      name: 'Lei Organica',
      href: '/legislacao/leiorganica',
    },

    {
      icon: './imagens/icons/transmissao.png',
      name: 'Transmissões',
      href: 'https://www.facebook.com/camaradegurjaopb',
    },






  ];

  return (
    <>

      {/* <Noticias /> */}

      <div class="container" style={{ marginTop: 140 }}>
        <div className="service-grid2">

          {serviceData.map((service, index) => (
            <Link style={{ textDecoration: "none" }} to={service.href} >
              <div className="service-card2" >

                <a><img src={service.icon} alt={service.name} />
                  <p>{service.name}</p></a>

              </div>
            </Link>

          ))}

        </div>
      </div>

      <section class="marginPD">
        <div class="">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="mb-0">Receitas e Despesas</h4>
              <p class="mb-0">Lei Nº 12.527 (Acesso a Informação) - Lei Complementar Nº 131 (Transparência)</p>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-1 row-cols-md-3 py-3">
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?e=101084%2C101084&ctx=101084%2C101084&Tab=3&isModal=false&tabs=off" class="stretched-link bs-receitas-orcamentarias-link text-decoration-none">
                    <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Receita Orçamentária</h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?e=101084%2C101084&ctx=101084%2C101084&Tab=3&isModal=false&tabs=off" class="stretched-link bs-receitas-extraorcamentarias-link text-decoration-none">
                    <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Receita Extraorçamentária</h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Fixada?e=101084%2C101084&ctx=101084%2C101084&Tab=1&isModal=false" class="stretched-link bs-despesas-orcamentarias-link text-decoration-none">
                    <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Despesa Orçamentária</h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Extra?e=101084%2C101084&ctx=101084%2C101084&Tab=4&isModal=false" class="stretched-link bs-despesas-extraorcamentarias-link text-decoration-none">
                    <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Despesa Extraorçamentária</h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Pagamentos?e=101084%2C101084&ctx=101084%2C101084&Tab=6&isModal=false" class="stretched-link bs-documentos-de-pagamento-link text-decoration-none">
                    <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Pagamentos</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section class="marginPD">
        <div class="">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="mb-0">Atos, publicações, pessoal, compras e convênios</h4>
              <p class="mb-0">Lei Nº 12.527 (Acesso a Informação)</p>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">

            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <Link to={"/legislacao/leismunicipais"}>
                    <a class="stretched-link bs--link text-decoration-none">
                      <i class="fas fa-balance-scale fs-3 d-block mb-3"></i>
                      <h3 class="h6 mb-0">Leis Municipais</h3>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <Link to={"/legislacao/decretos"}>
                    <a class="stretched-link bs--link text-decoration-none">
                      <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                      <h3 class="h6 mb-0">Decretos</h3>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <Link to={"/legislacao/leiorganica"}>
                    <a class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                      <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                      <h3 class="h6 mb-0">Lei Organica</h3>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="#" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                    <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Emendas Impositivas</h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <a href="https://transparencia.elmartecnologia.com.br/FolhaPag/Index/QuadroFuncional?e=101084&ctx=101084&Tab=1&isModal=false" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                    <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                    <h3 class="h6 mb-0">Pessoal</h3>
                  </a>
                </div>
              </div>
            </div>


            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <Link to={"/legislacao/diariosoficial"}>
                    <a class="stretched-link bs--link text-decoration-none">
                      <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                      <h3 class="h6 mb-0">Publicações</h3>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-hover card-hover-primary rounded-5 mb-3">
                <div class="card-body">
                  <Link to={"/arquivos/contratos"}>
                    <a class="stretched-link bs--link text-decoration-none">
                      <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                      <h3 class="h6 mb-0">Contratos</h3>
                    </a>
                  </Link>

                </div>
              </div>
            </div>




          </div>
        </div>
      </section>

      {/*     <div className="container mt-5">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
          <button class="button-title" data-text="Awesome">
            <span class="actual-text">&nbsp;&nbsp;NOSSOS&nbsp;VEREADORES&nbsp;</span>
            <span aria-hidden="true" class="hover-text">&nbsp;&nbsp;NOSSOS&nbsp;VEREADORES&nbsp;</span>
          </button>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4" style={{ display: 'flex', justifyContent: 'center' }} >
          {vereadores.map(renderVereadores)}
        </div>
      </div> */}


      <div className="container mt-5">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
          <button class="button-title" data-text="Awesome">
            <span class="actual-text">&nbsp;&nbsp;AVISOS&nbsp;A POPULAÇÃO&nbsp;</span>
            <span aria-hidden="true" class="hover-text">&nbsp;&nbsp;AVISOS&nbsp;A&nbsp;POPULAÇÃO&nbsp;</span>
          </button>
        </div>
      </div>
      <Novidades />

      <div class="cellview">
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <button class="button-title" data-text="Awesome">
            <span class="actual-text">&nbsp;&nbsp;ULTIMAS&nbsp;LEIS MUNICIPAIS&nbsp;</span>
            <span aria-hidden="true" class="hover-text">&nbsp;&nbsp;ULTIMAS&nbsp;LEIS&nbsp;MUNICIPAIS&nbsp;</span>
          </button>
        </div>

        <div class="containerLeis" style={{ alignContent: 'center', display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>


          {newsData.slice(0, 7).map(renderLeis)}




        </div>
      </div>
    </>
  );
}
