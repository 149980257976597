import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/cabecalho.css';
import './css/slide.css';
import './css/Menu.css';
import './css-wesome/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { faFacebook, faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import * as MuiIcons from '@mui/icons-material';

export default function Cabecalho({ config }) {

  const [vereadores, setVereadores] = useState([]);

  useEffect(() => {
    const database = getDatabase(firebaseApp);
    const vereadoresRef = ref(database, `${KEY_REF}/vereadores`);


    const unsubscribe = onValue(vereadoresRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const vereadoresArray = Object.keys(data).map(key => ({
          id: key,
          nome: data[key].nome
        }));
        setVereadores(vereadoresArray);
      } else {
        setVereadores([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const tempo = [1 * 60 * 60]

  const [maintenanceActive, setMaintenanceActive] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [maintenanceTime, setMaintenanceTime] = useState(0);
  const [maintenanceColor, setMaintenanceColor] = useState('');
  const [maintenanceTimeActive, setMaintenanceTimeActive] = useState(false);

  const isDesktop = window.innerWidth > 768;
  const currentDate = new Date().toLocaleDateString('pt-BR', {
    weekday: 'long',
    day: 'numeric',
    month: isDesktop ? 'long' : 'numeric',
    year: 'numeric',
  });

  const estiloIcones = {
    color: 'white',
    fontSize: '24px',
    marginLeft: '10px'
  };

  const estiloRedesSociais = {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50
  };

  const estiloMensagemManutencao = {
    color: 'white',
    backgroundColor: maintenanceActive ? maintenanceColor : 'blue',
    padding: '10px',
    textAlign: 'center',
  };


  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const maintenanceRef = ref(databaseInstance, `${KEY_REF}/manutencao`);

    onValue(maintenanceRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        setMaintenanceActive(data.ativo);
        setMaintenanceMessage(data.msg);
        setMaintenanceTime(data.tempo);
        setMaintenanceColor(data.cor);
        setMaintenanceTimeActive(data.time_ativo);
      }
    });
  }, []);




  return (


    <header >


      <nav className="navbar navbar-expand-lg navbar-dark header-background" style={{ background: config.background }}>

        <div className="container">
          <span className="navbar-brand"> </span>

          <span className='estiloData'><i class="fa-solid fa-calendar-days"></i>  Gurjão-PB, {currentDate}</span>

          <div style={estiloRedesSociais}>

            <Link to={"/transparencia"}><button className="btn btn-outline-light " style={{ backgroundColor: 'white', color: '#00436B' }}>Transparência</button></Link>


            <div className='linksredes'>
              <a href="https://www.facebook.com/camaradegurjaopb" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} style={estiloIcones} />
              </a>
              <a href="https://www.instagram.com/camaradegurjaopb/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} style={estiloIcones} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} style={estiloIcones} />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} style={estiloIcones} />
              </a>
            </div>

          </div>




        </div>
      </nav>

      <div className='slide'>
        <Link to={"/"}>
          <img className='imagem' src={config.banner} alt="Banner"></img>
        </Link>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ background: config.background }}>
        <div className="container-fluid">
          {/*       <Link className="navbar-brand " to="/">
            <img
              src="/imagens/logosimples.png"
              className={`logogurjao ${window.innerWidth <= 768 ? 'small-logo' : ''}`}
              alt="GURJÃO - PB"
              width="80px"
              height="78px"
            /></Link> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div style={{ marginLeft: 15 }} className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link className="nav-link active text-white" to={"/"}><i className="fa fa-home"></i></Link>


              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="prefeituraDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Nossa Câmara
                </a>
                <ul className="dropdown-menu" aria-labelledby="prefeituraDropdown">
                  <Link to={"/app/contato"} className="dropdown-item">Historia e Função</Link>
                  <Link to={"/app/contato"} className="dropdown-item">Contato</Link>

                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="legislacaoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Vereadores

                </a>
                <ul className="dropdown-menu" aria-labelledby="legislacaoDropdown">
                  {vereadores.map(vereador => (
                    <li key={vereador.id}>
                      <a className="dropdown-item" href={`/vereadores/${vereador.id}`}>{vereador.nome}</a>
                    </li>
                  ))}
                </ul>

              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="legislacaoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Atividades Legislativas
                </a>
                <ul className="dropdown-menu" aria-labelledby="legislacaoDropdown">
                  <li><Link className="dropdown-item" to={"/legislacao/requerimentos"}>Requerimentos</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/projetodelei"}>Projeto de Lei</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/pedidos_info"}>Pedidos Informações</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/mocoes"}>Moções</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/leismunicipais"}>Leis muncipais</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/leiorganica"}>Leis organica</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/diariosoficial"}>Diarios oficial</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/codigotributario"}>Codigo Tributario</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/decretos"}>Decretos</Link></li>
                  <li><Link className="dropdown-item" to={""}>LOA</Link></li>
                  <li><Link className="dropdown-item" to={""}>LDO</Link></li>
                  <li><Link className="dropdown-item" to={""}>RREO</Link></li>
                  <li><Link className="dropdown-item" to={""}>RGF</Link></li>

                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="/recursoshumanos" id="legislacaoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Mesa Diretora
                </a>
                <ul className="dropdown-menu" aria-labelledby="legislacaoDropdown">
                  <li><Link className="dropdown-item" to={"/"}>#</Link></li>
                </ul>
              </li>

              {config.destaque && config.destaque.active && (
                <li className="nav-item">
                  <Link className="nav-link active text-white fw-bold" to={config.destaque.link}>
                    {React.createElement(MuiIcons[config.destaque.icon])} {config.destaque.name}
                  </Link>
                </li>
              )}


            </ul>
            <form className="d-flex" role="search">
              <input className="form-control me-2" type="search" placeholder="Pesquise aqui..." aria-label="Search" />
              <button className="btn btn-custom-meu" style={{ backgroundColor: "white", color: '#088BD8' }} type="submit">Pesquisar</button>
            </form>
          </div>
        </div>
      </nav>




      {maintenanceActive && maintenanceTime > 0 && (
        <div style={estiloMensagemManutencao}>
          <i class="fa-solid fa-circle-exclamation"></i>
          {" " + maintenanceMessage}
          {maintenanceTimeActive ? (
            <b style={{ marginLeft: 8 }}>
              <i class="fa-regular fa-clock"></i> Tempo restante Estimado: {Math.floor(maintenanceTime / 3600)}h {Math.floor((maintenanceTime % 3600) / 60)}m {maintenanceTime % 60}s
            </b>
          ) : null}
        </div>
      )}



    </header>
  );
}
